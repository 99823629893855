export class DateUtil {

    public static DATE_TIME_INPUT_FORMAT: string = "YYYY-MM-DDTHH:mm:ss";

    public static toTimestamp(date: Date): number {
        if (date == null) {
            throw Error('Date is missing');
        }
        return date.getTime() / 1000;
    }

}
