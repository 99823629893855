import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout, debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {

  private headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
  private httpOptions = {headers: this.headers};
  TIME_OUT = 6000;
  DEBOUNCE_TIME = 1000;

  constructor(
    private http: HttpClient
  ) { }

  getForScheduling<T>(url: string): Observable<T> {
    return this.http.get<T>(url, this.httpOptions)
      .pipe(
        debounceTime(this.DEBOUNCE_TIME),
        timeout(this.TIME_OUT)
      );
  }
}
