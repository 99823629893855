import { Component, OnInit, Input } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Column } from './models/column.model';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from '../../services/crud.service';
import * as moment from 'moment';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.css']
})
export class DataGridComponent implements OnInit {
  
  @Input() recordApi;
  @Input() columns: Column[];
  @Input() title: string;
  @Input() idField: number;
  @Input() editPath: string;
  @Input() loadDataMethodName: string;
  @Input() loadExtraArgs: any[];
  @Input() initFilterState = [];
  @Input() filterRules: any = {};

  filterDebouncer = new Subject<any>();
  lastState: ClrDatagridStateInterface;
  isNavigating: boolean = false;
  loading: boolean = true;
  totalRecords: number;
  isDeleteConfirmModalShown: boolean;
  isDeleted: boolean = false;
  isDeleteError: boolean = false;
  deleteErrorMessage: string = 'There is an error when deleting this record';
  records: any[];
  currentRecord: any = {};
  requiredRoles = environment.role.ROLE_HUB_EAI_ROOT;
  

  constructor(private router: Router,
    private route: ActivatedRoute,
    private crudService: CrudService) { }

  ngOnInit() {
    this.handleFilter();
    this.crudService.recordDeletedEmitter$.subscribe(id => {
      this.refresh({});
    })
  }

  onView(record: any) {
    this.setNavigatingStatus();
    this.router.navigate([this.editPath], {
      queryParams: { mode: EditMode.VIEW, id: record[this.idField] },
      relativeTo: this.route
    })
  }

  onEdit(record: any) {
    this.setNavigatingStatus();
    this.router.navigate([this.editPath], {
      queryParams: { mode: EditMode.EDIT, id: record[this.idField] },
      relativeTo: this.route
    })
  }

  onOpenDeleteModal(record: any) {
    this.currentRecord = record;
    this.openDeleteConfirmModal();
    this.resetMessage();
  }

  onDelete(state: ClrDatagridStateInterface) {
    this.recordApi.delete(this.currentRecord[this.idField])
      .subscribe(res => {
        this.showSuccessfulDeleteMessage();
        this.refresh(this.lastState)
        this.crudService.recordDeletedEmitter$.emit(this.currentRecord[this.idField])
      }, err => {
        this.showErrorDeleteMessage(err.error.message);
      });
  }

  refresh(state: ClrDatagridStateInterface) {

    // Filters Object Structure
    // state.filters = [
    //   {
    //     'property': 'name',
    //     'value': 1
    //   }
    // ]
    
    // Handle the default filter behaviour
    if (state.filters != undefined && this.initFilterState != undefined) {
      Array.prototype.push.apply(state.filters, this.initFilterState);
    } else {
      state.filters = this.initFilterState;
    }

    if (this.isNavigating) {
      return;
    }

    this.lastState = state;

    // Debounce the filter event
    this.filterDebouncer.next(state);

  }

  private loadData(state: ClrDatagridStateInterface) {
    this.loading = true;

    // for load method with extra args, for example id
    const extraArgs = Array.prototype.concat(state, this.loadExtraArgs);
    
    this.recordApi[this.loadDataMethodName].apply(this.recordApi, extraArgs)
      .subscribe(res => {
        this.loading = false;
        this.records = res.data;
        this.totalRecords = res.count;
      });
  }

  private handleFilter() {
    this.filterDebouncer.asObservable().pipe(
      debounceTime(environment.appConfig.filterDebounceTime)
    ).subscribe(state => {
      if (state.filters !== undefined) {
        state.filters.forEach(c => {
          if (c.property === 'createdDate') {
            c.value = moment(c.value, 'DD-MM-YYYY').format("YYYY-MM-DD");
          }
          c['operator'] = this.filterRules[c.property]
        });
      }
      this.loadData(state);
    });
  }

  private openDeleteConfirmModal() {
    this.isDeleteConfirmModalShown = true;
  }

  private closeDeleteConfirmModal() {
    this.isDeleteConfirmModalShown = false;
  }

  private resetMessage() {
    this.isDeleted = false;
    this.isDeleteError = false;
  }

  private showSuccessfulDeleteMessage() {
    this.isDeleted = true;
    this.isDeleteError = !this.isDeleted;
  }

  private showErrorDeleteMessage(errMsg: string) {
    this.isDeleted = false;
    this.isDeleteError = !this.isDeleted;
    this.deleteErrorMessage = errMsg;
  }

  private setNavigatingStatus() {
    this.isNavigating = true;
  }

}
