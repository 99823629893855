import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CustomValidator } from 'src/app/shared/util/custom-validator';
import { ClrForm } from '@clr/angular';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { ProjectDropDown } from 'src/app/core/models/service-mgnt-api/project-drop-down.model';

@Component({
  selector: 'app-dynamic-func-edit',
  templateUrl: './dynamic-func-edit.component.html',
  styleUrls: ['./dynamic-func-edit.component.css']
})
export class DynamicFuncEditComponent implements OnInit {

  @ViewChild(ClrForm) clrForm;

  content;
  recordForm: FormGroup;
  logTopics;
  resultTopics;
  inputTopics;
  subscribers;
  isSaved: boolean = false;
  isSaveError: boolean = false;
  errorMsg: string = 'There is an error when saving this record';
  isViewMode: boolean = false;
  isEditMode: boolean = false;
  isAddMode: boolean = false;
  recordApi = this.serviceMgntApi.dynamicFunc;
  projects: ProjectDropDown[];

  config = {
    lineNumbers: true,
    lineWrapping: true,
    theme: "material",

    // AUTOCOMPLATE
    extraKeys: { "Ctrl-Space": "autocomplete" },
    // hintOptions: {hint: this.synonyms},

    // https://codemirror.net/mode/clike/index.html
    mime: "text/x-squirrel",
    mode: "python",
  };

  onFocus() {

  }
  onBlur() {

  }

  constructor(private serviceMgntApi: ServiceMgntApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.initForm();
    this.switchMode();
  }

  back() {
    this.location.back();
  }

  save() {
    if (!this.isValidForm()) {
      return;
    }
    this.recordApi.create(this.recordForm.value)
      .subscribe(res => {
        this.showSuccessMessage();
      }, err => {
        this.showErrorMessage(err.error.message);
      });
  }

  update() {
    if (!this.isValidForm()) {
      return;
    }
    const id = this.recordForm.value.dynamicFunctionId;
    console.log(id);
    this.recordApi.update(id, this.recordForm.value)
      .subscribe(res => {
        this.showSuccessMessage();
      }, err => {
        this.showErrorMessage(err.error.message);
      });
  }

  private initForm() {
    this.recordForm = new FormGroup({
      dynamicFunctionId: new FormControl(),
      projectId: new FormControl('', [Validators.required]),
      logTopicId: new FormControl('', [Validators.required]),
      resultTopicId: new FormControl('', [Validators.required]),
      inputTopicId: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      subscriptionName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      sourceCode: new FormControl(''),
      status: new FormControl('STARTED', [Validators.required]),
      // createdDate: new FormControl(),
    });
  }

  private switchMode() {
    this.route.queryParams.subscribe(params => {
      const _projectId = params.projectId;
      // ADD MODE
      if (_projectId !== undefined) {
        this.initTopicDropDownByProjectId(_projectId);
        this.initProjectDropDownByProjectId(_projectId);
        this.setDefaultProjectId(_projectId);
      } else {
        this.initTopicDropDown();
        this.initProjectDropDown();
      }
      switch (+params.mode) {
        case EditMode.ADD: {
          this.switchToAddMode();
          break;
        }
        case EditMode.EDIT: {
          this.switchToEditMode(params.id);
          break;
        }
        case EditMode.VIEW: {
          this.switchToViewMode(params.id);
          break;
        }
      }
    });
  }

  private switchToAddMode() {
    this.isAddMode = true;
    this.isViewMode = false;
    this.isEditMode = false;
  }

  private switchToEditMode(id: number) {
    this.isEditMode = true;
    this.isViewMode = false;
    this.isAddMode = false;
    this.initDataById(id);
  }

  private switchToViewMode(id: number) {
    this.isViewMode = true;
    this.isEditMode = false;
    this.isAddMode = false;
    this.initDataById(id);
  }

  private initTopicDropDownByProjectId(id: string) {
    this.serviceMgntApi.topic.getDropDownByProjectId(parseInt(id)).subscribe(res => {
      this.logTopics = res;
      this.resultTopics = res;
      this.inputTopics = res;
    });
  }

  private initTopicDropDown() {
    this.serviceMgntApi.topic.getDropDown().subscribe(res => {
      this.logTopics = res;
      this.resultTopics = res;
      this.inputTopics = res;
    });
  }

  private showSuccessMessage() {
    this.isSaved = true;
    this.isSaveError = false;
  }

  private showErrorMessage(errMsg: string) {
    this.isSaved = false;
    this.isSaveError = true;
    this.errorMsg = errMsg;
  }

  // Validate before submit
  private isValidForm(): boolean {
    if (this.recordForm.invalid) {
      this.clrForm.markAsDirty();
      return false;
    }
    return true;
  }

  private initDataById(id: number) {
    this.recordApi.findById(id)
      .subscribe(res => {
        this.recordForm.patchValue(res);
      });
  }

  private initProjectDropDownByProjectId(id: number) {
    this.serviceMgntApi.project.getDropDownByProjectId(id).subscribe(res => {
      this.projects = res;
    });
  }

  private initProjectDropDown() {
    this.serviceMgntApi.project.getDropDown().subscribe(res => {
      this.projects = res;
    });
  }

  private setDefaultProjectId(id: string) {
    this.recordForm.patchValue({
      projectId: parseInt(id)
    });
  }

}
