import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ClrForm, ClrDatagridStateInterface } from '@clr/angular';
import { FormGroup, FormControl } from '@angular/forms';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { Location } from '@angular/common';
import { DataGridComponent } from 'src/app/shared/components/data-grid/data-grid.component';
import { ProjectDropDown } from 'src/app/core/models/service-mgnt-api/project-drop-down.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-overview-detail',
  templateUrl: './overview-detail.component.html',
  styleUrls: ['./overview-detail.component.css']
})
export class OverviewDetailComponent implements OnInit {

  topicDeletedEmitter$: EventEmitter<Number>;

  @ViewChild(ClrForm) clrForm;
  @ViewChild('topicViewChild') topicViewChild: DataGridComponent;
  @ViewChild('pubViewChild') pubViewChild: DataGridComponent;
  @ViewChild('subViewChild') subViewChild: DataGridComponent;
  @ViewChild('dynViewChild') dynViewChild: DataGridComponent;

  recordForm: FormGroup;
  recordApi = this.serviceMgntApi.project;
  projectId;
  projects: ProjectDropDown[];
  selectedProject: ProjectDropDown;
  isViewMode = true;
  requiredRoles = environment.role.ROLE_HUB_EAI_ROOT;

  topicTitle = 'Topics'
  topicRecordApi = this.serviceMgntApi.topic;
  topicIdField = 'topicId';
  topicEditPath = '../../topics/edit';
  topicLoadDataFnName = 'getMetaData';
  topicInitFilterState;
  topicColumns = [
    {
      'name': 'Topic ID',
      'value': 'topicId',
      'type': 'number',
    },
    {
      'name': 'Topic Name',
      'value': 'topicName',
      'type': 'string',
    },
    {
      'name': 'Project Name',
      'value': 'projectName',
      'type': 'string'
    },
    {
      'name': 'Description',
      'value': 'description',
      'type': 'string'
    },
    {
      'name': 'Is Partitioned',
      'value': 'partitioned',
      'type': 'boolean'
    },
    {
      'name': 'Non Persistent',
      'value': 'nonPersistent',
      'type': 'boolean'
    },
    {
      'name': 'Routing Mode',
      'value': 'routingMode',
      'type': 'string'
    },
    {
      'name': 'Structure',
      'value': 'structure',
      'type': 'string'
    },
    {
      'name': 'Created Date',
      'value': 'createdDate',
      'type': 'date'
    }
  ]
  topicFilterRules = {
    topicId: "=",
    topicName: "~",
    projectName: "~",
    description: "~",
    partitioned: "~",
    routingMode: "~",
    nonPersistent: "~",
    structure: "~",
    createdDate: "~"
  }

  pubTitle = 'Publications'
  pubRecordApi = this.serviceMgntApi.publication;
  pubIdField = 'publicationId';
  pubEditPath = '../../publications/edit';
  pubLoadDataFnName = 'getMetaDataByProjectId';
  pubColumns = [
    {
      'name': 'Publication ID',
      'value': 'publicationId',
      'type': 'number'
    },
    {
      'name': 'Publication Name',
      'value': 'publicationName',
      'type': 'string'
    },
    {
      'name': 'Topic Name',
      'value': 'topicName',
      'type': 'string'
    },
    {
      'name': 'Publisher Name',
      'value': 'publisherName',
      'type': 'string'
    },
    {
      'name': 'Description',
      'value': 'description',
      'type': 'string'
    },
    {
      'name': 'Created Date',
      'value': 'createdDate',
      'type': 'date'
    },
  ]
  pubLoadExtraArgs;
  pubFilterRules = {
    publicationId: "=",
    publicationName: "~",
    topicName: "~",
    publisherName: "~",
    description: "~",
    createdDate: "~"
  }

  subTitle = 'Subscriptions'
  subRecordApi = this.serviceMgntApi.subscription;
  subIdField = 'subscriptionId';
  subEditPath = '../../subscriptions/edit';
  subLoadDataFnName = 'getMetaDataByProjectId';
  subColumns = [
    {
      'name': 'Subscription ID',
      'value': 'subscriptionId',
      'type': 'number'
    },
    {
      'name': 'Topic Name',
      'value': 'topicName',
      'type': 'string'
    },
    {
      'name': 'Subscriber Name',
      'value': 'subscriberName',
      'type': 'string'
    },
    {
      'name': 'Subscription Name',
      'value': 'subscriptionName',
      'type': 'string'
    },
    {
      'name': 'Retry Time',
      'value': 'retryTime',
      'type': 'number'
    },
    {
      'name': 'URI',
      'value': 'uri',
      'type': 'string'
    },
    {
      'name': 'Description',
      'value': 'description',
      'type': 'string'
    },
    {
      'name': 'Total Worker',
      'value': 'totalWorker',
      'type': 'number'
    },
    {
      'name': 'Start Time',
      'value': 'startTime',
      'type': 'dateTime'
    },
    {
      'name': 'Run Time',
      'value': 'runtime',
      'type': 'number'
    },
    {
      'name': 'Created Date',
      'value': 'createdDate',
      'type': 'date'
    },
  ]
  subLoadExtraArgs;
  subFilterRules = {
    subscriptionId: "=",
    topicName: "~",
    subscriberName: "~",
    subscriptionName: "~",
    retryTime: "=",
    uri: "~",
    description: "~",
    totalWorker: "=",
    startTime: "~",
    runTime: "=",
    createdDate: "~"
  }

  dynTitle = 'Dynamic Functions'
  dynRecordApi = this.serviceMgntApi.dynamicFunc;
  dynIdField = 'dynamicFunctionId';
  dynEditPath = '../../dynamicfunc/edit';
  dynLoadDataFnName = 'getMetaDataByProjectId';
  dynColumns = [
    {
      'name': 'Dync ID',
      'value': 'dynamicFunctionId',
      'type': 'number'
    },
    {
      'name': 'Input Topic Name',
      'value': 'inputTopicName',
      'type': 'string'
    },
    {
      'name': 'Result Topic Name',
      'value': 'resultTopicName',
      'type': 'string'
    },
    {
      'name': 'Log Topic Name',
      'value': 'logTopicName',
      'type': 'string'
    },
    {
      'name': 'Subscription Name',
      'value': 'subscriptionName',
      'type': 'string'
    },
    {
      'name': 'Source Code',
      'value': 'sourceCode',
      'type': 'string'
    },
    {
      'name': 'Status',
      'value': 'status',
      'type': 'string'
    },
    {
      'name': 'Description',
      'value': 'description',
      'type': 'string'
    },
    {
      'name': 'Created Date',
      'value': 'createdDate',
      'type': 'date'
    },
  ]
  dynLoadExtraArgs;
  dynFilterRules = {
    dynamicFunctionId: "=",
    logTopicName: "~",
    resultTopicName: "~",
    inputTopicName: "~",
    subscriptionName: "~",
    sourceCode: "~",
    status: "~",
    description: "~",
    createdDate: "~"
  }

  constructor(private serviceMgntApi: ServiceMgntApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.initForm();
    this.switchMode();
    this.initProjectDropDown();
  }

  back() {
    this.router.navigate(['../integration-projects/overview']);
  }

  changeProject(selectedId) {
    // Update current view project id
    this.projectId = selectedId;
    // Update URL for backing from other form behaviour
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          id: selectedId
        },
        queryParamsHandling: "merge", // remove to replace all query params by provided
      });
    // Update initState & Refresh Topic DataGrid 
    this.topicViewChild.initFilterState = [
      {
        'property': 'projectId',
        'value': this.projectId
      }
    ]
    this.topicViewChild.refresh({});
    // Refresh Pub DataGrid
    this.pubViewChild.loadExtraArgs = [this.projectId];
    this.pubViewChild.refresh({});
    // Refresh Sub DataGrid
    this.subViewChild.loadExtraArgs = [this.projectId];
    this.subViewChild.refresh({});
    // Refresh Dync DataGrid
    this.dynViewChild.loadExtraArgs = [this.projectId];
    this.dynViewChild.refresh({});
  }

  onTopicAdd() {
    this.router.navigate(['topics','edit'], {
      queryParams: {
        mode: EditMode.ADD,
        projectId: this.projectId
      },
      relativeTo: this.route
    })
  }

  onPubAdd() {
    this.router.navigate(['publications', 'edit'], {
      queryParams: {
        mode: EditMode.ADD,
        projectId: this.projectId
      },
      relativeTo: this.route
    })
  }

  onDynAdd() {
    this.router.navigate(['dynamicfunc', 'edit'], {
      queryParams: {
        mode: EditMode.ADD,
        projectId: this.projectId
      },
      relativeTo: this.route
    })
  }

  onSubAdd() {
    this.router.navigate(['subscriptions', 'edit'], {
      queryParams: {
        mode: EditMode.ADD,
        projectId: this.projectId
      },
      relativeTo: this.route
    })
  }

  private initForm() {
    this.recordForm = new FormGroup({
      projectId: new FormControl(),
      projectName: new FormControl(),
      description: new FormControl(),
    });
  }

  private initProjectDropDown() {
    this.serviceMgntApi.project.getDropDown().subscribe(res => {
      this.projects = res;
      this.selectedProject = this.projectId;
    });
  }

  private switchMode() {
    this.route.queryParams.subscribe(params => {
      this.projectId = params.id;
      // init view data
      this.initDataById(this.projectId);
      // set default filter for Topic datagrid
      this.topicInitFilterState = [
        {
          "property": 'projectId',
          // "operator": '=',
          "value": this.projectId
        }
      ]
      // set extraArgs for Pub and Sub datagrid
      this.pubLoadExtraArgs = [this.projectId];
      this.subLoadExtraArgs = [this.projectId];
      this.dynLoadExtraArgs = [this.projectId];
    });
  }

  private initDataById(id: number) {
    this.recordApi.findById(id)
      .subscribe(res => {
        this.recordForm.patchValue(res);
      });
  }

}
