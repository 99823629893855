import { Component, OnInit } from '@angular/core';
import { TopicMetaData } from 'src/app/core/models/service-mgnt-api/topic-meta-data.model';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { ClrDatagridStateInterface } from '@clr/angular';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-topics-list',
  templateUrl: './topics-list.component.html',
  styleUrls: ['./topics-list.component.css']
})
export class TopicsListComponent implements OnInit {

  filterDebouncer = new Subject<any>();
  lastState: ClrDatagridStateInterface;
  isNavigating: boolean = false;
  loading: boolean = true;
  totalRecords: number;
  isDeleteConfirmModalShown: boolean;
  isDeleted: boolean = false;
  isDeleteError: boolean = false;
  deleteErrorMessage: string = 'There is an error when deleting this record';
  recordApi = this.serviceMgntApi.topic;
  records: TopicMetaData[];
  currentRecord: TopicMetaData;
  filterRules = {
    topicId: "=",
    topicName: "~",
    projectName: "~",
    description: "~",
    partitioned: "~",
    routingMode: "~",
    nonPersistent: "~",
    structure: "~",
    createdDate: "~"
  }
  requiredRoles = environment.role.ROLE_HUB_EAI_ROOT;

  constructor(private serviceMgntApi: ServiceMgntApiService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.handleFilter();
  }

  onAdd() {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.ADD },
      relativeTo: this.route
    })
  }

  onView(record: TopicMetaData) {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.VIEW, id: record.topicId },
      relativeTo: this.route
    })
  }

  onEdit(record: TopicMetaData) {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.EDIT, id: record.topicId },
      relativeTo: this.route
    })
  }

  onOpenDeleteModal(record: TopicMetaData) {
    this.currentRecord = record;
    this.openDeleteConfirmModal();
    this.resetMessage();
  }

  onDelete(state: ClrDatagridStateInterface) {

    this.recordApi.delete(this.currentRecord.topicId)
      .subscribe(res => {
        this.showSuccessfulDeleteMessage();
        this.refresh(this.lastState)
      }, err => {
        this.showErrorDeleteMessage(err.error.message);
      });
  }

  refresh(state: ClrDatagridStateInterface) {
    
    if (this.isNavigating) {
      return;
    }

    this.lastState = state;

    this.loading = true;

    // Debounce the filter event
    this.filterDebouncer.next(state);

  }

  private loadData(state: ClrDatagridStateInterface) {
    this.recordApi.getMetaData(state)
      .subscribe(res => {
        this.loading = false;
        this.records = res.data;
        this.totalRecords = res.count;
      });
  }

  private handleFilter() {
    this.filterDebouncer.asObservable().pipe(
      debounceTime(environment.appConfig.filterDebounceTime)
    ).subscribe(state => {
      if (state.filters !== undefined) {
        state.filters.forEach(c => {
          if (c.property === 'createdDate') {
            c.value = moment(c.value, 'DD-MM-YYYY').format("YYYY-MM-DD");
          }
          c['operator'] = this.filterRules[c.property]
        });
      }
      this.loadData(state);
    });
  }

  private openDeleteConfirmModal() {
    this.isDeleteConfirmModalShown = true;
  }

  private closeDeleteConfirmModal() {
    this.isDeleteConfirmModalShown = false;
  }

  private resetMessage() {
    this.isDeleted = false;
    this.isDeleteError = false;
  }

  private showSuccessfulDeleteMessage() {
    this.isDeleted = true;
    this.isDeleteError = !this.isDeleted;
  }

  private showErrorDeleteMessage(errMsg: string) {
    this.isDeleted = false;
    this.isDeleteError = !this.isDeleted;
    this.deleteErrorMessage = errMsg;
  }

  private setNavigatingStatus() {
    this.isNavigating = true;
  }

}
