import {Component, OnInit} from '@angular/core';
import {PublisherDropDown} from '../../../../core/models/service-mgnt-api/publisher-drop-down.model';
import {ServiceMgntApiService} from '../../../../core/services/service-mgnt-api.service';
import {TopicDropDown} from '../../../../core/models/service-mgnt-api/topic-drop-down.model';
import {SubscriberDropDown} from '../../../../core/models/service-mgnt-api/subscriber-drop-down.model';
import {PublicationMetadata} from '../../../../core/models/service-mgnt-api/publication-metadata.model';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Page} from '../../../../core/models/service-mgnt-api/page.model';
import {SubscriptionMetaData} from '../../../../core/models/service-mgnt-api/subscription-meta-data.model';
import _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {EditMode} from '../../../../core/enums/edit-mode.enum';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
})
export class GraphComponent implements OnInit {

  publishers: PublisherDropDown[] = [];
  topics: TopicDropDown[] = [];
  subscribers: SubscriberDropDown[] = [];
  publications: PublicationMetadata[] = [];
  subscription: SubscriptionMetaData[] = [];
  state: ClrDatagridStateInterface = {
    page: {
      from: 0,
      to: 10,
      size: 20,
    },
    filters: [],
  };
  page: Page<PublicationMetadata>;
  pageSub: Page<SubscriptionMetaData>;

  viewHeight = 800;
  data: any[] = [];
  linePublication: any[] = [];
  lineSubscription: any[] = [];
  width = 120;
  height = 20;
  top = 40;
  left = 180;
  marginLeft = 60;
  marginTop = 15;
  select: any;
  roadMap: any;

  constructor(
    private serviceMgntApi: ServiceMgntApiService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  async ngOnInit() {
    this.publishers = await this.serviceMgntApi.publisher.getDropDown().toPromise();
    this.topics = await this.serviceMgntApi.topic.getDropDown().toPromise();
    this.subscribers = await this.serviceMgntApi.subscriber.getDropDown().toPromise();
    this.page = await this.serviceMgntApi.publication.getMetaData(this.state).toPromise();
    this.publications = this.page.data;
    this.pageSub = await this.serviceMgntApi.subscription.getMetaData(this.state).toPromise();
    this.subscription = this.pageSub.data;
    if (this.publishers.length > 0 && this.topics.length > 0 && this.subscribers.length > 0) {
      await this.formatData(this.data, this.linePublication, this.lineSubscription);
    }
  }

  getLinePathRow1(line) {
    const startX = this.left + this.width;
    const startY = this.top + this.height / 2 + (line.start - 1) * (this.height + this.marginTop);
    const endX = this.left + this.width + this.marginLeft;
    const endY = this.top + this.height / 2 + (line.end - 1) * (this.height + this.marginTop);
    return `M${startX},${startY} L${endX},${endY}`;
  }

  getLinePathRow2(line) {
    const startX = this.left + this.width * 2 + this.marginLeft;
    const startY = this.top + this.height / 2 + (line.start - 1) * (this.height + this.marginTop);
    const endX = this.left + this.width * 2 + this.marginLeft * 2;
    const endY = this.top + this.height / 2 + (line.end - 1) * (this.height + this.marginTop);
    return `M${startX},${startY} L${endX},${endY}`;
  }

  async formatData(data, linePublication: any[], lineSubscription: any[]) {
    const maxRowLength = Math.max(this.publishers.length, this.topics.length, this.subscribers.length);
    this.viewHeight = this.top + (this.height + this.marginTop) * (maxRowLength - 1) + 50;
    const arrayPub = [];
    const arrayTopic = [];
    const arraySub = [];
    this.publishers.map(item => {
      arrayPub.push({
        id: item.publisherId,
        label: item.publisherName,
        color: '#9DBAC1',
        colorLine: '#165382',
      });
      return arrayPub;
    });
    this.topics.map(item => {
      arrayTopic.push({
        id: item.topicId,
        label: item.topicName,
        color: '#91B9C4',
        colorLine: '#165382'
      });
      return arrayTopic;
    });
    this.subscribers.map(item => {
      arraySub.push({
        id: item.subscriberId,
        label: item.subscriberName,
        color: '#9DBAC1',
        colorLine: '#165382'
      });
      return arraySub;
    });
    data.push(arrayPub);
    data.push(arrayTopic);
    data.push(arraySub);

    this.publications.map(item => {
      const startIdx = _.findIndex(this.publishers, {publisherId: item.publisherId});
      const endIdx = _.findIndex(this.topics, {topicId: item.topicId});
      linePublication.push({
        start: startIdx + 1,
        end: endIdx + 1,
        publicationId: item.publicationId,
      });
      return linePublication;
    });
    this.subscription.map(item => {
      const startIdx = _.findIndex(this.topics, {topicId: item.topicId});
      const endIdx = _.findIndex(this.subscribers, {subscriberId: item.subscriberId});
      lineSubscription.push({
        start: startIdx + 1,
        end: endIdx + 1,
        subscriptionId: item.subscriptionId,
      });
      return lineSubscription;
    });
  }

  onClick(i, j) {
    this.select = {
      row: i,
      column: j,
    };
    this.getRoadMap(i, j);
  }

  getRoadMap(i, j) {
    // tslint:disable-next-line:one-variable-per-declaration
    let rect = [], line = [], line1 = [];
    switch (i) {
      case 0:
        // line 1 connect
        line1 = this.linePublication.map(item => {
          if ((item.start - 1) === j) {
            line.push({...item, type: 'pub'});
            rect.push({
              row: 1, column: item.end - 1
            });
            return {
              row: 1, column: item.end - 1
            };
          }
          return null;
        }).filter(x => x);

        // line 2 connect
        line1.forEach(re => {
          this.lineSubscription.forEach(item => {
            if ((item.start - 1) === re.column) {
              line.push({...item, type: 'sub'});
              rect.push({
                row: 2, column: item.end - 1,
              });
            }
          });
        });
        this.roadMap = {
          line, rect
        };
        break;
      case 1:
        this.linePublication.forEach(item => {
          if (item.end - 1 === j) {
            line.push({...item, type: 'pub'});
            rect.push({
              row: 0, column: item.start - 1
            });
          }
        });
        this.lineSubscription.forEach(item => {
          if (item.start - 1 === j) {
            line.push({...item, type: 'sub'});
            rect.push({
              row: 2, column: item.end - 1
            });
          }
        });
        this.roadMap = {
          line, rect
        };
        break;
      default:
        line1 = this.lineSubscription.map(item => {
          if ((item.end - 1) === j) {
            line.push({...item, type: 'sub'});
            rect.push({
              row: 1, column: item.start - 1
            });
            return {
              row: 1, column: item.start - 1
            };
          }
          return null;
        }).filter(x => x);
        line1.forEach(re => {
          this.linePublication.forEach(item => {
            if ((item.end - 1) === re.column) {
              line.push({...item, type: 'pub'});
              rect.push({
                row: 0, column: item.start - 1,
              });
            }
          });
        });
        this.roadMap = {
          line, rect
        };
        break;
    }
  }

  isRectConnect(i, j) {
    if (!this.select) {
      return false;
    }
    if (this.select.row === i && this.select.column === j) {
      return true;
    }
    if (!this.roadMap || !this.roadMap.rect || !this.roadMap.rect.length) {
      return false;
    }
    return _.findIndex(this.roadMap.rect, item => item.row === i && item.column === j) !== -1;
  }

  isLineConnect(start, end, type) {
    if (!this.select) {
      return false;
    }
    if (!this.roadMap || !this.roadMap.line || !this.roadMap.line.length) {
      return false;
    }
    return _.findIndex(this.roadMap.line, item => item.start === start && item.end === end && item.type === type) !== -1;
  }

  onSetNull() {
    this.select = null;
    this.roadMap = null;
  }

  onDetailPublisher(record, index) {
    if (index === 0) {
      this.router.navigate(['/integration-apps/publishers/edit'], {
        queryParams: {mode: EditMode.VIEW, id: record.id},
        relativeTo: this.route
      });
    }
    if (index === 2) {
      this.router.navigate(['/integration-apps/subscribers/edit'], {
        queryParams: {mode: EditMode.VIEW, id: record.id},
        relativeTo: this.route
      });
    }
    if (index === 1) {
      this.router.navigate(['/integration-projects/topics/edit'], {
        queryParams: {mode: EditMode.VIEW, id: record.id},
        relativeTo: this.route
      });
    }
  }

  onDetailPublication(record) {
    this.router.navigate(['/integration-projects/publications/edit'], {
      queryParams: {mode: EditMode.VIEW, id: record.publicationId},
      relativeTo: this.route
    });
  }

  onDetailSubscription(record) {
    this.router.navigate(['/integration-projects/subscriptions/edit'], {
      queryParams: {mode: EditMode.VIEW, id: record.subscriptionId},
      relativeTo: this.route
    });
  }


}
