import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClarityModule } from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GraphComponent } from './components/graph/graph.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import { CpuChartComponent } from './components/cpu-chart/cpu-chart.component';

@NgModule({
  declarations: [DashboardComponent, GraphComponent, LineChartComponent, CpuChartComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    ClarityModule,
    TranslateModule,
    NgxChartsModule,
    NgxGraphModule,
    NgApexchartsModule
  ]
})
export class DashboardModule { }
