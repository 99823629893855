import {Component, OnInit} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexXAxis,
  ApexStroke, ApexGrid, ApexYAxis
} from 'ng-apexcharts';
import {Subscription, timer} from 'rxjs';
import {BrokerStatsService} from '../../../../core/services/broker-stats.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css'],
})
export class LineChartComponent implements OnInit {

  public series: ApexAxisChartSeries;
  public chart: ApexChart;
  public dataLabels: ApexDataLabels;
  public xaxis: ApexXAxis;
  public yaxis: ApexYAxis;
  public stroke: ApexStroke;
  public grid: ApexGrid;

  reportLoader: Subscription;
  loading = true;
  dataChart: any[] = [];

  constructor(private service: BrokerStatsService) {

  }

  async ngOnInit() {
    this.loadReport();
    await this.initChartData();
  }

  loadReport() {
    this.reportLoader = timer(0, 5000).subscribe(val => {
      this.loading = true;
      this.service.getLoadReportInSchedule()
        .pipe(
          finalize(() => this.loading = false)
        )
        .subscribe(
          data => {
            if (data) {
              this.dataChart.push(data['memory']['usage'] * 100 / data['memory']['limit']);
              if (this.dataChart.length > 10) {
                this.dataChart.shift();
              }
              this.series = [
                {
                  name: '%Memory Usage',
                  data: this.dataChart,
                }
              ];
            }
          },
          error => {
            console.log(error);
            this.loading = false;
          }
        );
    });
  }

  async initChartData() {
    this.series = [];
    this.stroke = {
      curve: 'straight',
    };
    this.grid = {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    };
    this.chart = {
      height: 140,
      type: 'line',
      zoom: {
        enabled: false,
      }
    };
    this.dataLabels = {
      enabled: false,
    };
    this.xaxis = {
      categories: ['50s', '45s', '40s', '35s', '30s', '25s', '20s', '15s', '10s', '5s'],
    };
    this.yaxis = {
      labels: {
        formatter(val) {
          return `${(val).toFixed(0)} %`;
        }
      },
      min: 0,
      max: 99,
      forceNiceScale: true,
      tickAmount: 5,
    };
  }

}
