import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntegrationAppsRoutingModule } from './integration-apps-routing.module';
import { SubscribersComponent } from './components/subscribers/subscribers.component';
import { ClarityModule } from '@clr/angular';
import { PublishersListComponent } from './components/publishers/publishers-list/publishers-list.component';
import { PublishersEditComponent } from './components/publishers/publishers-edit/publishers-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscribersListComponent } from './components/subscribers/subscribers-list/subscribers-list.component';
import { SubscribersEditComponent } from './components/subscribers/subscribers-edit/subscribers-edit.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';

@NgModule({
  declarations: [SubscribersComponent, PublishersListComponent, PublishersEditComponent, SubscribersListComponent, SubscribersEditComponent],
  imports: [
    CommonModule,
    IntegrationAppsRoutingModule,
    SharedModule,
    ClarityModule, 
    ReactiveFormsModule,
    TranslateModule,
    TrimValueAccessorModule
  ]
})
export class IntegrationAppsModule { }
