import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Directive({
  selector: '[appHideIfUnauthorized]'
})
export class HideIfUnauthorizedDirective implements OnInit {

  // Array of permissions
  @Input("appHideIfUnauthorized") requiredRoles;

  constructor(private el: ElementRef,
    private kc: KeycloakService) {

  }

  ngOnInit() {
    const currentUserRoles = this.kc.getUserRoles();

    var permissionArr: string[] = [];
    console.log(permissionArr);
    

    this.requiredRoles.split(',').forEach(r => {
      permissionArr.push(r.trim());
    });

    const isValid = !permissionArr.some(r => {
      return currentUserRoles.indexOf(r) < 0;
    });

    if (!isValid) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
