import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigurationsRoutingModule } from './configurations-routing.module';
import { ConfigurationsComponent } from './components/configurations/configurations.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ConfigurationsComponent],
  imports: [
    CommonModule,
    ConfigurationsRoutingModule,
    ClarityModule, 
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class ConfigurationsModule { }
