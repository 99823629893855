import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  public recordDeletedEmitter$: EventEmitter<Number>;

  constructor() { 
    this.recordDeletedEmitter$ = new EventEmitter();
  }

}
