import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { TopicDropDown } from 'src/app/core/models/service-mgnt-api/topic-drop-down.model';
import { SubscriberDropDown } from 'src/app/core/models/service-mgnt-api/subscriber-drop-down.model';
import { DateUtil } from 'src/app/shared/util/date-util';
import { ClrForm } from '@clr/angular';
import { CustomValidator } from 'src/app/shared/util/custom-validator';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-subscriptions-edit',
  templateUrl: './subscriptions-edit.component.html',
  styleUrls: ['./subscriptions-edit.component.css']
})
export class SubscriptionsEditComponent implements OnInit {

  @ViewChild(ClrForm) clrForm;

  recordForm: FormGroup;
  isSaved: boolean = false;
  isSaveError: boolean = false;
  errorMsg: string = 'There is an error when saving this record';
  isViewMode: boolean = false;
  isEditMode: boolean = false;
  isAddMode: boolean = false;
  topics: TopicDropDown[];
  subscribers: SubscriberDropDown[];
  selectedCommType: string;
  recordApi = this.serviceMgntApi.subscription;

  constructor(private serviceMgntApi: ServiceMgntApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.initForm();
    this.switchMode();
  }

  save() {
    if (!this.isValidForm()) {
      return;
    }
    // convert to timestamptz
    this.recordForm.value.startTime = new Date(this.recordForm.value.startTime).getTime();
    this.recordApi.create(this.recordForm.value)
      .subscribe(res => {
        this.showSuccessMessage();
      }, err => {
        this.showErrorMessage(err.error.message);
      });
  }

  update() {
    if (!this.isValidForm()) {
      return;
    }
    const id = this.recordForm.value.subscriptionId;
    // convert to timestamptz
    this.recordForm.value.startTime = new Date(this.recordForm.value.startTime).getTime();
    this.recordApi.update(id, this.recordForm.value)
      .subscribe(res => {
        this.showSuccessMessage();
      }, err => {
        this.showErrorMessage(err.error.message);
      });
  }

  back() {
    this.location.back();
  }

  private initForm() {
    this.recordForm = new FormGroup({
      subscriptionId: new FormControl(),
      topicId: new FormControl('', [Validators.required]),
      subscriberId: new FormControl('', [Validators.required]),
      subscriptionName: new FormControl('', [Validators.required, CustomValidator.noWhitespace, Validators.maxLength(100)]),
      retryTime: new FormControl(0, [Validators.required, CustomValidator.noNegetiveNumber, Validators.pattern("^[0-9]*$")]),
      uri: new FormControl('', [CustomValidator.noWhitespace, CustomValidator.urlRequired, Validators.maxLength(255)]),
      description: new FormControl('', [Validators.required, CustomValidator.noWhitespace, Validators.maxLength(255)]),
      totalWorker: new FormControl(1, [Validators.required, CustomValidator.noNegetiveNumber, Validators.pattern("^[0-9]*$")]),
      startTime: new FormControl(moment(new Date()).format(DateUtil.DATE_TIME_INPUT_FORMAT), [Validators.required]),
      runtime: new FormControl(0, [Validators.required, CustomValidator.noNegetiveNumber, Validators.pattern("^[0-9]*$")]),
    });
  }

  private switchMode() {
    this.route.queryParams.subscribe(params => {
      const projectId = params.projectId;
      // Behaviour when ProjectID is set
      if (params.projectId !== undefined) {
        this.initTopicDropDownByProjectId(projectId);
      } else {
        this.initTopicDropDown();
      }
      this.initSubscriberDropDown();
      switch (+params.mode) {
        case EditMode.ADD: {
          this.switchToAddMode();
          break;
        }
        case EditMode.EDIT: {
          this.switchToEditMode(params.id);
          break;
        }
        case EditMode.VIEW: {
          this.switchToViewMode(params.id);
          break;
        }
      }
    });
  }

  private switchToAddMode() {
    this.isAddMode = true;
    this.isViewMode = false;
    this.isEditMode = false;
  }

  private switchToEditMode(id: number) {
    this.isEditMode = true;
    this.isViewMode = false;
    this.isAddMode = false;
    this.initDataById(id);
  }

  private switchToViewMode(id: number) {
    this.isViewMode = true;
    this.isEditMode = false;
    this.isAddMode = false;
    this.initDataById(id);
  }

  private showSuccessMessage() {
    this.isSaved = true;
    this.isSaveError = false;
  }

  private showErrorMessage(errMsg: string) {
    this.isSaved = false;
    this.isSaveError = true;
    this.errorMsg = errMsg;
  }

  private initDataById(id: number) {
    this.recordApi.findById(id)
      .subscribe(res => {
        res.startTime = moment(res.startTime).format(DateUtil.DATE_TIME_INPUT_FORMAT);
        this.recordForm.patchValue(res);
      });
  }

  private initTopicDropDownByProjectId(id: string) {
    this.serviceMgntApi.topic.getDropDownByProjectId(parseInt(id)).subscribe(res => {
      this.topics = res;
    });
  }

  private initTopicDropDown() {
    this.serviceMgntApi.topic.getDropDown().subscribe(res => {
      this.topics = res;
    });
  }

  private initSubscriberDropDown() {
    this.serviceMgntApi.subscriber.getDropDown().subscribe(res => {
      this.subscribers = res;
    });
  }

  // Validate before submit
  private isValidForm(): boolean {
    if (this.recordForm.invalid) {
      this.clrForm.markAsDirty();
      return false;
    }
    return true;
  }

}
