import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {CommonHttpService} from './common-http.service';


@Injectable({
  providedIn: 'root'
})
export class BrokerStatsService {

  constructor(private http: CommonHttpService) {
  }

  // In scheduling, no retry if error
  getLoadReportInSchedule() {
    // const url = 'http://192.168.31.211:8083/broker-stats/load-report';
    const url = environment.serviceMgntApi.loadReport;
    return this.http.getForScheduling<object>(url);
  }
}
