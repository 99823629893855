import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TopicDropDown } from 'src/app/core/models/service-mgnt-api/topic-drop-down.model';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { PublisherDropDown } from 'src/app/core/models/service-mgnt-api/publisher-drop-down.model';
import { ClrForm } from '@clr/angular';
import { CustomValidator } from 'src/app/shared/util/custom-validator';
import { Location } from '@angular/common';

@Component({
  selector: 'app-publications-edit',
  templateUrl: './publications-edit.component.html',
  styleUrls: ['./publications-edit.component.css']
})
export class PublicationsEditComponent implements OnInit {

  @ViewChild(ClrForm) clrForm;

  recordForm: FormGroup;
  isSaved: boolean = false;
  isSaveError: boolean = false;
  errorMsg: string = 'There is an error when saving this record';
  isViewMode: boolean = false;
  isEditMode: boolean = false;
  isAddMode: boolean = false;
  topics: TopicDropDown[];
  publishers: PublisherDropDown[];
  selectedCommType: string;
  recordApi = this.serviceMgntApi.publication;

  constructor(private serviceMgntApi: ServiceMgntApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.initForm();
    this.switchMode();
  }

  save() {
    if (!this.isValidForm()) {
      return;
    }
    this.recordApi.create(this.recordForm.value)
      .subscribe(res => {
        this.showSuccessMessage();
      }, err => {
        this.showErrorMessage(err.error.message);
      });
  }

  update() {
    if (!this.isValidForm()) {
      return;
    }
    const id = this.recordForm.value.publicationId;
    this.recordApi.update(id, this.recordForm.value)
      .subscribe(res => {
        this.showSuccessMessage();
      }, err => {
        this.showErrorMessage(err.error.message);
      });
  }

  back() {
    this.location.back();
  }

  private initForm() {
    this.recordForm = new FormGroup({
      publicationId: new FormControl(),
      topicId: new FormControl('', [Validators.required]),
      publisherId: new FormControl('', [Validators.required]),
      publicationName: new FormControl('', [Validators.required, CustomValidator.noWhitespace, Validators.maxLength(100)]),
      description: new FormControl('', [Validators.required, CustomValidator.noWhitespace, Validators.maxLength(255)])
    });
  }

  private switchMode() {
    this.route.queryParams.subscribe(params => {
      const projectId = params.projectId;
      // Behaviour when ProjectID is set
      if (params.projectId !== undefined) {
        this.initTopicDropDownByProjectId(projectId);
      } else {
        this.initTopicDropDown();
      }
      this.initPublisherDropDown();
      switch (+params.mode) {
        case EditMode.ADD: {
          this.switchToAddMode();
          break;
        }
        case EditMode.EDIT: {
          this.switchToEditMode(params.id);
          break;
        }
        case EditMode.VIEW: {
          this.switchToViewMode(params.id);
          break;
        }
      }
    });
  }

  private switchToAddMode() {
    this.isAddMode = true;
    this.isViewMode = false;
    this.isEditMode = false;
  }

  private switchToEditMode(id: number) {
    this.isEditMode = true;
    this.isViewMode = false;
    this.isAddMode = false;
    this.initDataById(id);
  }

  private switchToViewMode(id: number) {
    this.isViewMode = true;
    this.isEditMode = false;
    this.isAddMode = false;
    this.initDataById(id);
  }

  private showSuccessMessage() {
    this.isSaved = true;
    this.isSaveError = false;
  }

  private showErrorMessage(errMsg: string) {
    this.isSaved = false;
    this.isSaveError = true;
    this.errorMsg = errMsg;
  }

  private initDataById(id: number) {
    this.recordApi.findById(id)
      .subscribe(res => {
        this.recordForm.patchValue(res);
      });
  }

  private initTopicDropDownByProjectId(id: string) {
    this.serviceMgntApi.topic.getDropDownByProjectId(parseInt(id)).subscribe(res => {
      this.topics = res;
    });
  }

  private initTopicDropDown() {
    this.serviceMgntApi.topic.getDropDown().subscribe(res => {
      this.topics = res;
    });
  }

  private initPublisherDropDown() {
    this.serviceMgntApi.publisher.getDropDown().subscribe(res => {
      this.publishers = res;
    });
  }

  // Validate before submit
  private isValidForm(): boolean {
    if (this.recordForm.invalid) {
      this.clrForm.markAsDirty();
      return false;
    }
    return true;
  }

}
