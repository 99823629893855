import { KeycloakOptions } from 'keycloak-angular';

// KeyCloak
const _keyCloakOptions: KeycloakOptions = {
  config: {
    url: window["env"]["KEYCLOAK_URL"],
    realm: window["env"]["KEYCLOAK_REALM"],
    // realm: 'EnterpriseAdminRealm',
    clientId: window["env"]["KEYCLOAK_CLIENT_ID"],
    // clientId: 'login-app'
  },
  initOptions: {
    onLoad: 'login-required',
    checkLoginIframe: false
  },
  enableBearerInterceptor: true,
  bearerExcludedUrls: ['/assets', '/clients/public']
};
// vIntegration Hub Service Management URL
const BASE_SERVICE_MGNT_URL = window["env"]["BASE_SERVICE_MGNT_URL"]; // proxy server
const DYNAMIC_FUNC_URL = `${BASE_SERVICE_MGNT_URL}/dynamicfunc`
const INTEGRATION_FLOW_URL = `${BASE_SERVICE_MGNT_URL}/integrations`
const PROJECT_URL = `${BASE_SERVICE_MGNT_URL}/project`
const PUBLICATION_URL = `${BASE_SERVICE_MGNT_URL}/publication`
const PUBLISHER_URL = `${BASE_SERVICE_MGNT_URL}/publisher`
const SUBSCRIBER_URL = `${BASE_SERVICE_MGNT_URL}/subscriber`
const SUBSCRIPTION_URL = `${BASE_SERVICE_MGNT_URL}/subscription`
const TOPIC_URL = `${BASE_SERVICE_MGNT_URL}/topic`
const COMM_TYPE_URL = `${BASE_SERVICE_MGNT_URL}/commtype`
const ROUTING_MODE_URL = `${BASE_SERVICE_MGNT_URL}/routingMode`
const STATISTIC_URL = `${BASE_SERVICE_MGNT_URL}/statistic`
// load report cpu and memory
const BASE_PULSAR_ADMIN = window["env"]["BASE_PULSAR_ADMIN"] ;
const LOAD_REPORT = `${BASE_PULSAR_ADMIN}/broker-stats/load-report`;
// vIntegration Hub Service Management API
const DYNAMIC_FUNC_API = {
  base: DYNAMIC_FUNC_URL,
  detail(id: number) {
    return `${DYNAMIC_FUNC_URL}/${id}`;
  },
  metadata: `${DYNAMIC_FUNC_URL}/metadata`
};

const INTEGRATION_FLOW_API = {
  base: INTEGRATION_FLOW_URL,
  detail(id: number) {
    return `${INTEGRATION_FLOW_URL}/${id}`;
  }
};

const PROJECT_API = {
  base: PROJECT_URL,
  detail(id: number) {
    return `${PROJECT_URL}/${id}`;
  },
  dropdown: `${PROJECT_URL}/dropdown`,
  metadata: `${PROJECT_URL}/metadata`
};

const PUBLICATION_API = {
  base: PUBLICATION_URL,
  detail(id: number) {
    return `${PUBLICATION_URL}/${id}`;
  },
  metadata: `${PUBLICATION_URL}/metadata`,
  metadataByProjectId(id: number) {
    return `${PUBLICATION_URL}/metadata/project/${id}`;
  },
};

const PUBLISHER_API = {
  base: PUBLISHER_URL,
  detail(id: number) {
    return `${PUBLISHER_URL}/${id}`;
  },
  metaData: `${PUBLISHER_URL}/metadata`,
  dropdown: `${PUBLISHER_URL}/dropdown`
};
const LOAD_REPORT_API = {
  base: LOAD_REPORT,
  detail(id: number) {
    return `${LOAD_REPORT}/${id}`;
  },
};

const SUBSCRIBER_API = {
  base: SUBSCRIBER_URL,
  detail(id: number) {
    return `${SUBSCRIBER_URL}/${id}`;
  },
  metaData: `${SUBSCRIBER_URL}/metadata`,
  dropdown: `${SUBSCRIBER_URL}/dropdown`
};

const SUBSCRIPTION_API = {
  base: SUBSCRIPTION_URL,
  detail(id: number) {
    return `${SUBSCRIPTION_URL}/${id}`;
  },
  metaData: `${SUBSCRIPTION_URL}/metadata`,
  metaDataDetail(id: number) {
    return `${SUBSCRIPTION_URL}/metadata/${id}`;
  },
  metaDataByProjectId(id: number) {
    return `${SUBSCRIPTION_URL}/metadata/project/${id}`;
  }
};

const TOPIC_API = {
  base: TOPIC_URL,
  detail(id: number) {
    return `${TOPIC_URL}/${id}`;
  },
  dropdown: `${TOPIC_URL}/dropdown`,
  metaData: `${TOPIC_URL}/metadata`,
}

const COMM_TYPE_API = {
  base: COMM_TYPE_URL,
  detail(id: number) {
    return `${COMM_TYPE_URL}/${id}`;
  }
};


const ROUTING_MODE_API = {
  base: ROUTING_MODE_URL,
  detail(id: number) {
    return `${ROUTING_MODE_URL}/${id}`;
  }
};

const STATISTIC_API = {
  base: STATISTIC_URL,
  integratedApp: `${STATISTIC_URL}/integratedApp`,
  integrationProject: `${STATISTIC_URL}/integrationProject`,
};

const SERVICE_MGNT_API = {
  dynamicFunc: DYNAMIC_FUNC_API,
  integrationFlow: INTEGRATION_FLOW_API,
  project: PROJECT_API,
  publication: PUBLICATION_API,
  publisher: PUBLISHER_API,
  subscriber: SUBSCRIBER_API,
  subscription: SUBSCRIPTION_API,
  topic: TOPIC_API,
  commType: COMM_TYPE_API,
  routingMode: ROUTING_MODE_API,
  statistic: STATISTIC_API,
  loadReport: LOAD_REPORT,
};

const APP_CONFIG = {
  filterDebounceTime: 300
};

const ROLE = {
  ROLE_HUB_EAI_ROOT: 'ROLE_HUB_EAI_ROOT',
  ROLE_HUB_MANAGER_ROOT: 'ROLE_HUB_MANAGER_ROOT'
};

export const environment = {
  production: false,
  serviceMgntApi: SERVICE_MGNT_API,
  keyCloakOptions: _keyCloakOptions,
  appConfig: APP_CONFIG,
  role: ROLE,
};


