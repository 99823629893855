import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DynamicFunc } from '../models/service-mgnt-api/dynamic-func.model';
import { Publisher } from '../models/service-mgnt-api/publisher.model';
import { CommType } from '../models/service-mgnt-api/comm-type.model';
import { PublisherMetaData } from '../models/service-mgnt-api/publisher-meta-data.model';
import { SubscriberMetaData } from '../models/service-mgnt-api/subscriber-meta-data.model';
import { Subscriber } from '../models/service-mgnt-api/subscriber.model';
import { Project } from '../models/service-mgnt-api/project.model';
import { Subscription } from '../models/service-mgnt-api/subscription.model';
import { SubscriptionMetaData } from '../models/service-mgnt-api/subscription-meta-data.model';
import { SubscriberDropDown } from '../models/service-mgnt-api/subscriber-drop-down.model';
import { TopicDropDown } from '../models/service-mgnt-api/topic-drop-down.model';
import { Publication } from '../models/service-mgnt-api/publication.model';
import { PublicationMetadata } from '../models/service-mgnt-api/publication-metadata.model';
import { PublisherDropDown } from '../models/service-mgnt-api/publisher-drop-down.model';
import { TopicMetaData } from '../models/service-mgnt-api/topic-meta-data.model';
import { Topic } from '../models/service-mgnt-api/topic.model';
import { ProjectDropDown } from '../models/service-mgnt-api/project-drop-down.model';
import { RoutingMode } from '../models/service-mgnt-api/routing-mode.model';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Page } from '../models/service-mgnt-api/page.model';
import { ProjectMetaData } from '../models/service-mgnt-api/project-meta-data.model';
import { IntegrationProjectMetadata } from '../models/service-mgnt-api/integration-project-metadata.model';
import { IntegratedAppMetadata } from '../models/service-mgnt-api/integrated-app-metadata.model';
import { DynamicFuncMetadata } from '../models/service-mgnt-api/dynamic-func-meta-data.mode';

@Injectable({
  providedIn: 'root'
})
export class ServiceMgntApiService {

  private readonly SERVICE_MGNT_API = environment.serviceMgntApi;

  constructor(private httpClient: HttpClient) { }

  public dynamicFunc = new class {

    private readonly DYNAMIC_FUNC_API = this.instance.SERVICE_MGNT_API.dynamicFunc;
    private readonly pageableConverter = this.instance.convertFilterToPageableUrl;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: DynamicFunc): Observable<DynamicFunc> {
      return this.instance.httpClient.post<DynamicFunc>(this.DYNAMIC_FUNC_API.base, entity);
    }

    public read(): Observable<DynamicFunc[]> {
      return this.instance.httpClient.get<DynamicFunc[]>(this.DYNAMIC_FUNC_API.base);
    }

    public update(id: number, entity: DynamicFunc): Observable<DynamicFunc> {
      return this.instance.httpClient.put<DynamicFunc>(this.DYNAMIC_FUNC_API.detail(id), entity);
    }

    public delete(id: number): Observable<any> {
      return this.instance.httpClient.delete(this.DYNAMIC_FUNC_API.detail(id));
    }

    public findById(id: number): Observable<DynamicFunc> {
      return this.instance.httpClient.get<DynamicFunc>(this.DYNAMIC_FUNC_API.detail(id));
    }

    public getMetaData(state: ClrDatagridStateInterface): Observable<Page<DynamicFuncMetadata>> {
      return this.instance.httpClient.get<Page<DynamicFuncMetadata>>(this.pageableConverter(this.DYNAMIC_FUNC_API.metadata, state));
    }

    public getMetaDataByProjectId(state: ClrDatagridStateInterface, id: number): Observable<Page<SubscriptionMetaData>> {
      let isContainProjectId = false;
      state.filters.forEach(r => {
        if (r.property === 'projectId') {
          r.value = id;
          isContainProjectId = true;
        }
      })
      if (!isContainProjectId) {
        state.filters.push({
          property: 'projectId',
          operator: '=',
          value: id
        })
      }
      return this.instance.httpClient.get<Page<SubscriptionMetaData>>(this.pageableConverter(this.DYNAMIC_FUNC_API.metadata, state));
    }

  }(this);

  public integrationFlow = new class {

    private readonly INTEGRATION_FLOW_API = this.instance.SERVICE_MGNT_API.integrationFlow;

    constructor(private instance: ServiceMgntApiService) { }

    public create(): Observable<any> {
      return this.instance.httpClient.post(this.INTEGRATION_FLOW_API.base, '');
    }

    public read(): Observable<any> {
      return this.instance.httpClient.get(this.INTEGRATION_FLOW_API.base);
    }

    public update(id: number): Observable<any> {
      return this.instance.httpClient.put(this.INTEGRATION_FLOW_API.detail(id), '');
    }

    public delete(id: number): Observable<any> {
      return this.instance.httpClient.delete(this.INTEGRATION_FLOW_API.detail(id));
    }

    public findById(id: number): Observable<any> {
      return this.instance.httpClient.get(this.INTEGRATION_FLOW_API.detail(id));
    }

  }(this);

  public project = new class {

    private readonly PROJECT_API = this.instance.SERVICE_MGNT_API.project;
    private readonly pageableConverter = this.instance.convertFilterToPageableUrl;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: Project): Observable<Project> {
      return this.instance.httpClient.post<Project>(this.PROJECT_API.base, entity);
    }

    public read(): Observable<Project[]> {
      return this.instance.httpClient.get<Project[]>(this.PROJECT_API.base);
    }

    public update(id: number, entity: Project): Observable<Project> {
      if (id == null) {
        throw Error('Id is missing');
      }
      return this.instance.httpClient.put<Project>(this.PROJECT_API.detail(id), entity);
    }

    public delete(id: number): Observable<Project> {
      return this.instance.httpClient.delete<Project>(this.PROJECT_API.detail(id));
    }

    public findById(id: number): Observable<Project> {
      return this.instance.httpClient.get<Project>(this.PROJECT_API.detail(id));
    }

    public getDropDown(): Observable<ProjectDropDown[]> {
      return this.instance.httpClient.get<ProjectDropDown[]>(this.PROJECT_API.dropdown);
    }

    public getDropDownByProjectId(id: number): Observable<ProjectDropDown[]> {
      return this.instance.httpClient.get<ProjectDropDown[]>(`${this.PROJECT_API.dropdown}?q=projectId,=,${id}`);
    }

    public getMetaData(state: ClrDatagridStateInterface): Observable<Page<ProjectMetaData>> {
      return this.instance.httpClient.get<Page<ProjectMetaData>>(this.pageableConverter(this.PROJECT_API.metadata, state));
    }

  }(this);

  public publication = new class {

    private readonly PUBLICATION_API = this.instance.SERVICE_MGNT_API.publication;
    private readonly pageableConverter = this.instance.convertFilterToPageableUrl;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: Publication): Observable<Publication> {
      return this.instance.httpClient.post<Publication>(this.PUBLICATION_API.base, entity);
    }

    public read(): Observable<Publication[]> {
      return this.instance.httpClient.get<Publication[]>(this.PUBLICATION_API.base);
    }

    public update(id: number, entity: Publication): Observable<Publication> {
      return this.instance.httpClient.put<Publication>(this.PUBLICATION_API.detail(id), entity);
    }

    public delete(id: number): Observable<Publication> {
      return this.instance.httpClient.delete<Publication>(this.PUBLICATION_API.detail(id));
    }

    public findById(id: number): Observable<Publication> {
      return this.instance.httpClient.get<Publication>(this.PUBLICATION_API.detail(id));
    }

    public getMetaData(state: ClrDatagridStateInterface): Observable<Page<PublicationMetadata>> {
      return this.instance.httpClient.get<Page<PublicationMetadata>>(this.pageableConverter(this.PUBLICATION_API.metadata, state));
    }
    public getMetaDataByProjectId(state: ClrDatagridStateInterface, id: number): Observable<Page<PublicationMetadata>> {
      // tslint:disable-next-line:max-line-length
      return this.instance.httpClient.get<Page<PublicationMetadata>>(this.pageableConverter(this.PUBLICATION_API.metadataByProjectId(id), state));
    }
  }(this);

  public publisher = new class {

    private readonly PUBLISHER_API = this.instance.SERVICE_MGNT_API.publisher;
    private readonly pageableConverter = this.instance.convertFilterToPageableUrl;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: Publisher): Observable<Publisher> {
      return this.instance.httpClient.post<Publisher>(this.PUBLISHER_API.base, entity);
    }

    public read(): Observable<Publisher[]> {
      return this.instance.httpClient.get<Publisher[]>(this.PUBLISHER_API.base);
    }

    public update(id: number, entity: Publisher): Observable<Publisher> {
      return this.instance.httpClient.put<Publisher>(this.PUBLISHER_API.detail(id), entity);
    }

    public delete(id: number): Observable<Publisher> {
      return this.instance.httpClient.delete<Publisher>(this.PUBLISHER_API.detail(id));
    }

    public findById(id: number): Observable<Publisher> {
      return this.instance.httpClient.get<Publisher>(this.PUBLISHER_API.detail(id));
    }

    public getMetaData(state: ClrDatagridStateInterface): Observable<Page<PublisherMetaData>> {
      return this.instance.httpClient.get<Page<PublisherMetaData>>(this.pageableConverter(this.PUBLISHER_API.metaData, state));
    }

    public getDropDown(): Observable<PublisherDropDown[]> {
      return this.instance.httpClient.get<PublisherDropDown[]>(this.PUBLISHER_API.dropdown);
    }

  }(this);

  public subscriber = new class {

    private readonly SUBSCRIBER_API = this.instance.SERVICE_MGNT_API.subscriber;
    private readonly pageableConverter = this.instance.convertFilterToPageableUrl;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: Subscriber): Observable<Subscriber> {
      return this.instance.httpClient.post<Subscriber>(this.SUBSCRIBER_API.base, entity);
    }

    public read(): Observable<Subscriber[]> {
      return this.instance.httpClient.get<Subscriber[]>(this.SUBSCRIBER_API.base);
    }

    public update(id: number, entity: Subscriber): Observable<Subscriber> {
      if (id == null) {
        throw Error('Id is missing');
      }
      return this.instance.httpClient.put<Subscriber>(this.SUBSCRIBER_API.detail(id), entity);
    }

    public delete(id: number): Observable<Subscriber> {
      return this.instance.httpClient.delete<Subscriber>(this.SUBSCRIBER_API.detail(id));
    }

    public findById(id: number): Observable<Subscriber> {
      return this.instance.httpClient.get<Subscriber>(this.SUBSCRIBER_API.detail(id));
    }

    public getMetaData(state: ClrDatagridStateInterface): Observable<Page<SubscriberMetaData>> {
      return this.instance.httpClient.get<Page<SubscriberMetaData>>(this.pageableConverter(this.SUBSCRIBER_API.metaData, state));
    }

    public getDropDown(): Observable<SubscriberDropDown[]> {
      return this.instance.httpClient.get<SubscriberDropDown[]>(this.SUBSCRIBER_API.dropdown);
    }

  }(this);

  public subscription = new class {

    private readonly SUBSCRIPTION_API = this.instance.SERVICE_MGNT_API.subscription;
    private readonly pageableConverter = this.instance.convertFilterToPageableUrl;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: Subscription): Observable<Subscription> {
      return this.instance.httpClient.post<Subscription>(this.SUBSCRIPTION_API.base, entity);
    }

    public read(): Observable<Subscription[]> {
      return this.instance.httpClient.get<Subscription[]>(this.SUBSCRIPTION_API.base);
    }

    public update(id: number, entity: Subscription): Observable<Subscription> {
      if (id == null) {
        throw Error('Id is missing');
      }
      return this.instance.httpClient.put<Subscription>(this.SUBSCRIPTION_API.detail(id), entity);
    }

    public delete(id: number): Observable<Subscription> {
      return this.instance.httpClient.delete<Subscription>(this.SUBSCRIPTION_API.detail(id));
    }

    public findById(id: number): Observable<Subscription> {
      return this.instance.httpClient.get<Subscription>(this.SUBSCRIPTION_API.detail(id));
    }

    public getMetaData(state: ClrDatagridStateInterface): Observable<Page<SubscriptionMetaData>> {
      return this.instance.httpClient.get<Page<SubscriptionMetaData>>(this.pageableConverter(this.SUBSCRIPTION_API.metaData, state));
    }

    public getMetaDataById(id: number): Observable<SubscriptionMetaData> {
      return this.instance.httpClient.get<SubscriptionMetaData>(this.SUBSCRIPTION_API.metaDataDetail(id));
    }

    public getMetaDataByProjectId(state: ClrDatagridStateInterface, id: number): Observable<Page<SubscriptionMetaData>> {
      return this.instance.httpClient.get<Page<SubscriptionMetaData>>(this.pageableConverter(this.SUBSCRIPTION_API.metaDataByProjectId(id), state));
    }

  }(this);

  public topic = new class {

    private readonly TOPIC_API = this.instance.SERVICE_MGNT_API.topic;
    private readonly pageableConverter = this.instance.convertFilterToPageableUrl;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: Topic): Observable<Topic> {
      return this.instance.httpClient.post<Topic>(this.TOPIC_API.base, entity);
    }

    public read(): Observable<Topic[]> {
      return this.instance.httpClient.get<Topic[]>(this.TOPIC_API.base);
    }

    public update(id: number, entity: Topic): Observable<Topic> {
      if (id == null) {
        throw Error('Id is missing');
      }
      return this.instance.httpClient.put<Topic>(this.TOPIC_API.detail(id), entity);
    }

    public delete(id: number): Observable<Topic> {
      return this.instance.httpClient.delete<Topic>(this.TOPIC_API.detail(id));
    }

    public findById(id: number): Observable<Topic> {
      return this.instance.httpClient.get<Topic>(this.TOPIC_API.detail(id));
    }

    public getMetaData(state: ClrDatagridStateInterface): Observable<Page<TopicMetaData>> {
      return this.instance.httpClient.get<Page<TopicMetaData>>(this.pageableConverter(this.TOPIC_API.metaData, state));
    }

    public getDropDown(): Observable<TopicDropDown[]> {
      return this.instance.httpClient.get<TopicDropDown[]>(this.TOPIC_API.dropdown);
    }

    public getDropDownByProjectId(id: number): Observable<TopicDropDown[]> {
      return this.instance.httpClient.get<TopicDropDown[]>(`${this.TOPIC_API.dropdown}?q=projectId,=,${id}`);
    }

  }(this);

  public commType = new class {

    private readonly COMM_TYPE_API = this.instance.SERVICE_MGNT_API.commType;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: CommType): Observable<CommType> {
      return this.instance.httpClient.post<CommType>(this.COMM_TYPE_API.base, entity);
    }

    public read(): Observable<CommType[]> {
      return this.instance.httpClient.get<CommType[]>(this.COMM_TYPE_API.base);
    }

    public update(id: number, entity: CommType): Observable<CommType> {
      return this.instance.httpClient.put<CommType>(this.COMM_TYPE_API.detail(id), entity);
    }

    public delete(id: number): Observable<CommType> {
      return this.instance.httpClient.delete<CommType>(this.COMM_TYPE_API.detail(id));
    }

    public findById(id: number): Observable<CommType> {
      return this.instance.httpClient.get<CommType>(this.COMM_TYPE_API.detail(id));
    }

  }(this);

  public routingMode = new class {

    private readonly ROUTING_MODE_API = this.instance.SERVICE_MGNT_API.routingMode;

    constructor(private instance: ServiceMgntApiService) { }

    public create(entity: RoutingMode): Observable<RoutingMode> {
      return this.instance.httpClient.post<RoutingMode>(this.ROUTING_MODE_API.base, entity);
    }

    public read(): Observable<RoutingMode[]> {
      return this.instance.httpClient.get<RoutingMode[]>(this.ROUTING_MODE_API.base);
    }

    public update(id: number, entity: RoutingMode): Observable<RoutingMode> {
      return this.instance.httpClient.put<RoutingMode>(this.ROUTING_MODE_API.detail(id), entity);
    }

    public delete(id: number): Observable<RoutingMode> {
      return this.instance.httpClient.delete<RoutingMode>(this.ROUTING_MODE_API.detail(id));
    }

    public findById(id: number): Observable<RoutingMode> {
      return this.instance.httpClient.get<RoutingMode>(this.ROUTING_MODE_API.detail(id));
    }

  }(this);

  public statistic = new class {

    private readonly STATISTIC_API = this.instance.SERVICE_MGNT_API.statistic;

    constructor(private instance: ServiceMgntApiService) { }

    public getIntegratedAppMetadata(): Observable<IntegratedAppMetadata> {
      return this.instance.httpClient.get<IntegratedAppMetadata>(this.STATISTIC_API.integratedApp);
    }

    public getIntegrationProjectMetadata(): Observable<IntegrationProjectMetadata> {
      return this.instance.httpClient.get<IntegrationProjectMetadata>(this.STATISTIC_API.integrationProject);
    }

  }(this);

  public static createQuery(filters) {

    let queryUrl = '';

    if (filters === undefined) {
      return queryUrl;
    }

    filters.forEach(c => {
      // default if no operator is specified
      if (c.operator === undefined) {
        c.operator = '=';
      }
      queryUrl += `&q=${c.property},${c.operator},${c.value}`;
    });

    return queryUrl;
  }

  private static isNumber(value: string | number): boolean {
    return ((value != null) && !isNaN(Number(value.toString())));
  }

  private convertFilterToPageableUrl(baseUrl: string,
                                     state: ClrDatagridStateInterface) {

    let queryUrl = ServiceMgntApiService.createQuery(state.filters);

    // At first times, 'sort' object will be 'undefined',
    // so we need to init value for it
    if (state.sort === undefined) {
      state.sort = {
        reverse: true,
        by: 'createdDate'
      }
    }

    // Sometimes 'state.page' could be undefined, set the default
    // value for it
    if (state.page === undefined) {
      state.page = {
        from: 0,
        size: 10
      }
    }

    const pageable = {
      p: Math.floor(state.page.from / state.page.size),
      l: state.page.size
    }
    const sort = {
      d: !state.sort.reverse ? 'ASC' : 'DESC',
      f: state.sort.by
    }
    return `${baseUrl}?page=${pageable.p}&size=${pageable.l}&sort=${sort.f},${sort.d}${queryUrl}`
  }

}
