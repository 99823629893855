import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublishersListComponent } from './components/publishers/publishers-list/publishers-list.component';
import { PublishersEditComponent } from './components/publishers/publishers-edit/publishers-edit.component';
import { SubscribersListComponent } from './components/subscribers/subscribers-list/subscribers-list.component';
import { SubscribersEditComponent } from './components/subscribers/subscribers-edit/subscribers-edit.component';

const routes: Routes = [
  {
    path: 'integration-apps',
    children: [
      {
        path: 'publishers',
        children: [
          {
            path: '',
            component: PublishersListComponent
          },
          {
            path: 'edit',
            component: PublishersEditComponent
          },
        ]
      },
      {
        path: 'subscribers',
        children: [
          {
            path: '',
            component: SubscribersListComponent
          },
          {
            path: 'edit',
            component: SubscribersEditComponent
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationAppsRoutingModule { }
