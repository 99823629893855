import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommType } from 'src/app/core/models/service-mgnt-api/comm-type.model';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { ClrForm } from '@clr/angular';
import { Location } from '@angular/common';
import { CustomValidator } from 'src/app/shared/util/custom-validator';

@Component({
  selector: 'app-subscribers-edit',
  templateUrl: './subscribers-edit.component.html',
  styleUrls: ['./subscribers-edit.component.css']
})
export class SubscribersEditComponent implements OnInit {

  @ViewChild(ClrForm) clrForm;

  recordForm: FormGroup;
  isSaved: boolean = false;
  isSaveError: boolean = false;
  errorMsg: string = 'There is an error when saving this record';
  isViewMode: boolean = false;
  isEditMode: boolean = false;
  isAddMode: boolean = false;
  commTypes: CommType[];
  selectedCommType: string;
  recordApi = this.serviceMgntApi.subscriber;

  constructor(private serviceMgntApi: ServiceMgntApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.initData();
    this.initForm();
    this.switchMode();
  }

  save() {
    if (!this.isValidForm()) {
      return;
    }
    this.recordApi.create(this.recordForm.value)
      .subscribe(res => {
        this.showSuccessMessage();
      }, err => {
        this.showErrorMessage(err.error.message);
      });
  }

  update() {
    if (!this.isValidForm()) {
      return;
    }
    let id = this.recordForm.value.subscriberId;
    this.recordApi.update(id, this.recordForm.value)
      .subscribe(res => {
        this.showSuccessMessage();
      }, err => {
        this.showErrorMessage(err.error.message);
      });
  }

  back() {
    this.location.back();
  }

  private initForm() {
    this.recordForm = new FormGroup({
      subscriberId: new FormControl(),
      commId: new FormControl(1, Validators.required),
      refKey: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      subscriberName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      integrationFlow: new FormControl('false', Validators.required),
    });
  }

  private switchMode() {
    this.route.queryParams.subscribe(params => {
      switch (+params.mode) {
        case EditMode.ADD: {
          this.switchToAddMode();
          break;
        }
        case EditMode.EDIT: {
          this.switchToEditMode(params.id);
          break;
        }
        case EditMode.VIEW: {
          this.switchToViewMode(params.id);
          break;
        }
      }
    });
  }

  private switchToAddMode() {
    this.isAddMode = true;
    this.isViewMode = false;
    this.isEditMode = false;
  }

  private switchToEditMode(id: number) {
    this.isEditMode = true;
    this.isViewMode = false;
    this.isAddMode = false;
    this.initDataById(id);
  }

  private switchToViewMode(id: number) {
    this.isViewMode = true;
    this.isEditMode = false;
    this.isAddMode = false;
    this.initDataById(id);
  }

  private showSuccessMessage() {
    this.isSaved = true;
    this.isSaveError = false;
  }

  private showErrorMessage(errMsg: string) {
    this.isSaved = false;
    this.isSaveError = true;
    this.errorMsg = errMsg;
  }

  private initDataById(id: number) {
    this.recordApi.findById(id)
      .subscribe(res => {
        this.recordForm.patchValue(res);
      });
  }

  private initData() {
    this.serviceMgntApi.commType.read()
      .subscribe(res => {
        this.commTypes = res;
      });
  }

  // Validate before submit
  private isValidForm(): boolean {
    if (this.recordForm.invalid) {
      this.clrForm.markAsDirty();
      return false;
    }
    return true;
  }

}
