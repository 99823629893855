import { AbstractControl } from '@angular/forms';

export class CustomValidator {

    public static noWhitespace(control: AbstractControl): { [key: string]: boolean } | null {
        let isWhitespace = (control.value || '').trim().length === 0;
        return !isWhitespace ? null : { 'whitespace': true }
    }

    public static noNegetiveNumber(control: AbstractControl): { [key: string]: boolean } | null {
        const _value = control.value;
        if (isNaN(_value)) {
            return { 'negativeNumber': true }
        }
        return _value >= 0 ? null : { 'negativeNumber': true }
    }

    public static urlRequired(control: AbstractControl): { [key: string]: boolean } | null {
        const expression = /\b((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/gi;
        return expression.test(control.value) ? null : { 'urlRequired': true }
    }

    public static integerRequired(control: AbstractControl): { [key: string]: boolean } | null {
        const _value = control.value;
        return Number.isInteger(_value) ? null : { 'integerRequired': true }
    }

}
