import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toString'
})
export class ToStringPipe implements PipeTransform {

  transform(value: any): string {
    if (value == null) {
      return '';
    }
    if (typeof value == 'boolean') {
      return value ? 'true' : 'false'
    }
    if (typeof value == 'number') {
      return value.toString();
    }
  }

}
