import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  // languages
  vietnamese = 'vi'
  english = 'en'
  // state
  currentLang: string = this.english
  defaultLang: string = this.english
  // cookie key
  language = 'lang';
  // options
  options = {
    expires: this.addYear(1)
  }

  constructor(private toastr: ToastrService,
    private translate: TranslateService,
    private cookieService: CookieService) {
    if (this.isLangSet()) {
      this.currentLang = this.cookieService.get(this.language);
      this.translate.use(this.currentLang);
    } else {
      this.cookieService.put(this.language, this.english, this.options);
      this.currentLang = this.english;
    }
  }

  public saveNApply(language: string) {
    this.cookieService.put(this.language, language, this.options);
    this.translate.use(language);
    this.currentLang = language;
  }

  public getDefaultLanguage() {
    return this.cookieService.get(this.language) === undefined ? this.english : this.vietnamese;
  }

  public changeLanguage() {
    console.log(this.currentLang);
    if (this.currentLang === this.english) {
      this.currentLang = this.vietnamese;
      this.toastr.info('Đã chuyển đổi thành công ngôn ngữ sang tiếng Việt', 'THÔNG BÁO');
    } else {
      this.currentLang = this.english;
      this.toastr.info('Changed language to English', 'NOTIFICATION');
    }
    this.translate.use(this.currentLang);
    // save for futher use
    this.cookieService.put(this.language, this.currentLang, this.options);
  }

  private isLangSet() {
    return this.cookieService.get(this.language) !== undefined;
  }

  private addYear(n: number): Date {
    var now = new Date();
    return new Date(now.setFullYear(now.getFullYear() + n));
  }

}
