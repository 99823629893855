import { Component, OnInit } from '@angular/core';
import { IntegratedAppMetadata } from 'src/app/core/models/service-mgnt-api/integrated-app-metadata.model';
import { IntegrationProjectMetadata } from 'src/app/core/models/service-mgnt-api/integration-project-metadata.model';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private serviceMgntApi: ServiceMgntApiService) { }

  integratedApp: IntegratedAppMetadata;
  integrationProject: IntegrationProjectMetadata;
  memory = '%Memory Usage';
  cpu = '%CPU Usage';

  ngOnInit() {
    this.loadIntegratedAppMetadata();
    this.loadIntegrationProjectMetadata();
  }

  private loadIntegrationProjectMetadata() {
    this.serviceMgntApi.statistic.getIntegrationProjectMetadata().subscribe(res => {
      this.integrationProject = res;
    });
  }

  private loadIntegratedAppMetadata() {
    this.serviceMgntApi.statistic.getIntegratedAppMetadata().subscribe(res => {
      this.integratedApp = res;
    });
  }

}
