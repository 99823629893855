import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { SideNavComponent } from './shared/layout/side-nav/side-nav.component';
import { SubNavComponent } from './shared/layout/sub-nav/sub-nav.component';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { IntegrationAppsModule } from './features/integration-apps/integration-apps.module';
import { IntegrationProjectsModule } from './features/integration-projects/integration-projects.module';

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './shared/util/app-init';
import { ActivitiesModule } from './features/activities/activities.module';
import { ActivitiesComponent } from './features/activities/activities/activities.component';
import { FormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// import Cookie Module
import { CookieModule } from 'ngx-cookie';
import { SharedModule } from './shared/shared.module';
import { ConfigurationsModule } from './features/configurations/configurations.module';

import {BreadcrumbModule} from 'angular-crumbs';
// import { DirectiveDirective } from 'directive.directive';
import { HideIfUnauthorizedDirective } from './shared/directive/hide-if-unauthorized.directive';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    SideNavComponent,
    SubNavComponent,
    ActivitiesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    DashboardModule,
    IntegrationAppsModule,
    IntegrationProjectsModule,
    ActivitiesModule,
    ConfigurationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    FormsModule,
    ToastrModule.forRoot(),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CookieModule.forRoot(),
    BreadcrumbModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
