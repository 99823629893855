import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ProjectsEditComponent } from './components/projects/projects-edit/projects-edit.component';
import { SubscriptionsListComponent } from './components/subscriptions/subscriptions-list/subscriptions-list.component';
import { SubscriptionsEditComponent } from './components/subscriptions/subscriptions-edit/subscriptions-edit.component';
import { PublicationsListComponent } from './components/publications/publications-list/publications-list.component';
import { PublicationsEditComponent } from './components/publications/publications-edit/publications-edit.component';
import { TopicsListComponent } from './components/topics/topics-list/topics-list.component';
import { TopicsEditComponent } from './components/topics/topics-edit/topics-edit.component';
import { OverviewComponent } from './components/overview/overview.component';
import { OverviewDetailComponent } from './components/overview-detail/overview-detail.component';
import { DynamicFuncEditComponent } from './components/dynamic-func/dynamic-func-edit/dynamic-func-edit.component';
import { DynamicFuncListComponent } from './components/dynamic-func/dynamic-func-list/dynamic-func-list.component';

const routes: Routes = [
  {
    path: 'integration-projects',
    children: [
      {
        path: 'overview',
        children: [
          {
            path: '',
            component: OverviewComponent
          },
          {
            path: 'edit',
            children: [
              {
                path: '',
                component: OverviewDetailComponent
              },
              {
                path: 'topics/edit',
                component: TopicsEditComponent
              },
              {
                path: 'publications/edit',
                component: PublicationsEditComponent
              },
              {
                path: 'subscriptions/edit',
                component: SubscriptionsEditComponent
              },
              {
                path: 'dynamicfunc/edit',
                component: DynamicFuncEditComponent
              }
            ]
          },
          {
            path: 'projects',
            children: [
              {
                path: 'edit',
                component: ProjectsEditComponent
              }
            ]
          },          
        ]
      },
      {
        path: 'subscriptions',
        children: [
          {
            path: '',
            component: SubscriptionsListComponent
          },
          {
            path: 'edit',
            component: SubscriptionsEditComponent
          }
        ]
      },
      {
        path: 'publications',
        children: [
          {
            path: '',
            component: PublicationsListComponent
          },
          {
            path: 'edit',
            component: PublicationsEditComponent
          }
        ]
      },
      {
        path: 'topics',
        children: [
          {
            path: '',
            component: TopicsListComponent
          },
          {
            path: 'edit',
            component: TopicsEditComponent
          }
        ]
      },
      {
        path: 'dynamicfuncs',
        children: [
          {
            path: '',
            component: DynamicFuncListComponent
          },
          {
            path: 'edit',
            component: DynamicFuncEditComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationProjectsRoutingModule { }
