import { Component, OnInit } from '@angular/core';
import { PublicationMetadata } from 'src/app/core/models/service-mgnt-api/publication-metadata.model';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { ClrDatagridStateInterface } from '@clr/angular';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-publications-list',
  templateUrl: './publications-list.component.html',
  styleUrls: ['./publications-list.component.css']
})
export class PublicationsListComponent implements OnInit {

  filterDebouncer = new Subject<any>();
  lastState: ClrDatagridStateInterface;
  isNavigating = false;
  loading = true;
  totalRecords: number;
  isDeleteConfirmModalShown: boolean;
  isDeleted = false;
  isDeleteError = false;
  deleteErrorMessage = 'There is an error when deleting this record';
  recordApi = this.serviceMgntApi.publication;
  records: PublicationMetadata[];
  currentRecord: PublicationMetadata;
  filterRules = {
    publicationId: '=',
    publicationName: '~',
    topicName: '~',
    publisherName: '~',
    description: '~',
    createdDate: '~'
  };
  requiredRoles = environment.role.ROLE_HUB_EAI_ROOT;

  constructor(private serviceMgntApi: ServiceMgntApiService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.handleFilter();
  }

  onAdd() {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.ADD },
      relativeTo: this.route
    });
  }

  onView(record: PublicationMetadata) {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.VIEW, id: record.publicationId },
      relativeTo: this.route
    });
  }

  onEdit(record: PublicationMetadata) {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.EDIT, id: record.publicationId },
      relativeTo: this.route
    });
  }

  onOpenDeleteModal(record: PublicationMetadata) {
    this.currentRecord = record;
    this.openDeleteConfirmModal();
    this.resetMessage();
  }

  onDelete(state: ClrDatagridStateInterface) {

    this.recordApi.delete(this.currentRecord.publicationId)
      .subscribe(res => {
        this.showSuccessfulDeleteMessage();
        this.refresh(this.lastState);
      }, err => {
        this.showErrorDeleteMessage(err.error.message);
      });
  }

  refresh(state: ClrDatagridStateInterface) {

    if (this.isNavigating) {
      return;
    }

    this.lastState = state;

    this.loading = true;

    // Debounce the filter event
    this.filterDebouncer.next(state);

  }

  private loadData(state: ClrDatagridStateInterface) {
    this.recordApi.getMetaData(state)
      .subscribe(res => {
        this.loading = false;
        this.records = res.data;
        this.totalRecords = res.count;
      });
  }

  private handleFilter() {
    this.filterDebouncer.asObservable().pipe(
      debounceTime(environment.appConfig.filterDebounceTime)
    ).subscribe(state => {
      if (state.filters !== undefined) {
        state.filters.forEach(c => {
          if (c.property === 'createdDate') {
            c.value = moment(c.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
          }
          c.operator = this.filterRules[c.property];
        });
      }
      this.loadData(state);
    });
  }

  private openDeleteConfirmModal() {
    this.isDeleteConfirmModalShown = true;
  }

  private closeDeleteConfirmModal() {
    this.isDeleteConfirmModalShown = false;
  }

  private resetMessage() {
    this.isDeleted = false;
    this.isDeleteError = false;
  }

  private showSuccessfulDeleteMessage() {
    this.isDeleted = true;
    this.isDeleteError = !this.isDeleted;
  }

  private showErrorDeleteMessage(errMsg: string) {
    this.isDeleted = false;
    this.isDeleteError = !this.isDeleted;
    this.deleteErrorMessage = errMsg;
  }

  private setNavigatingStatus() {
    this.isNavigating = true;
  }

}
