import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntegrationProjectsRoutingModule } from './integration-projects-routing.module';
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ProjectsEditComponent } from './components/projects/projects-edit/projects-edit.component';
import { ClarityModule } from '@clr/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SubscriptionsListComponent } from './components/subscriptions/subscriptions-list/subscriptions-list.component';
import { SubscriptionsEditComponent } from './components/subscriptions/subscriptions-edit/subscriptions-edit.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PublicationsEditComponent } from './components/publications/publications-edit/publications-edit.component';
import { PublicationsListComponent } from './components/publications/publications-list/publications-list.component';
import { TopicsListComponent } from './components/topics/topics-list/topics-list.component';
import { TopicsEditComponent } from './components/topics/topics-edit/topics-edit.component';
import { OverviewComponent } from './components/overview/overview.component';
import { OverviewDetailComponent } from './components/overview-detail/overview-detail.component';
import { DynamicFuncEditComponent } from './components/dynamic-func/dynamic-func-edit/dynamic-func-edit.component';

import 'codemirror';
import { CodemirrorModule } from 'ng2-codemirror';
import { TranslateModule } from '@ngx-translate/core';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { DynamicFuncListComponent } from './components/dynamic-func/dynamic-func-list/dynamic-func-list.component';

@NgModule({
  declarations: [ProjectsListComponent, ProjectsEditComponent, SubscriptionsListComponent, SubscriptionsEditComponent, PublicationsEditComponent, PublicationsListComponent, TopicsListComponent, TopicsEditComponent, OverviewComponent, OverviewDetailComponent, DynamicFuncEditComponent, DynamicFuncListComponent],
  imports: [
    CommonModule,
    IntegrationProjectsRoutingModule,
    ClarityModule, 
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    CodemirrorModule,
    TranslateModule,
    TrimValueAccessorModule
  ]
})
export class IntegrationProjectsModule { }
