import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToStringPipe } from './pipes/to-string.pipe';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { ClarityModule } from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { HideIfUnauthorizedDirective } from './directive/hide-if-unauthorized.directive';

@NgModule({
  declarations: [ToStringPipe, DataGridComponent, HideIfUnauthorizedDirective],
  imports: [
    CommonModule,
    ClarityModule,
    TranslateModule
  ],
  exports: [ToStringPipe, DataGridComponent, HideIfUnauthorizedDirective]
})
export class SharedModule { }