import {Component, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {LanguageService} from 'src/app/core/services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  username = '';
  role: string = '';

  constructor(
    private ks: KeycloakService,
    private ls: LanguageService,
  ) {

  }

  ngOnInit() {
    try {
      this.ks.loadUserProfile().then(user => {
        this.username = user.username;
      });
      const lstString = this.ks.getUserRoles();
      this.role = lstString[0];

    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  logout() {
    this.ks.logout();
  }

  changeLanguage() {
    this.ls.changeLanguage();
  }

}
