import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.css']
})
export class ConfigurationsComponent implements OnInit {

  isSaved: boolean;
  configForm: FormGroup;

  constructor(private location: Location,
    private langService: LanguageService) { }

  ngOnInit() {
    this.configForm = new FormGroup({
      language: new FormControl(this.langService.getDefaultLanguage(), Validators.required),
    });
  }

  back() {
    this.location.back();
  }

  save() {
    this.langService.saveNApply(this.configForm.value.language);
    this.isSaved = true;
  }

}
