import { Component, OnInit } from '@angular/core';
import { ServiceMgntApiService } from 'src/app/core/services/service-mgnt-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditMode } from 'src/app/core/enums/edit-mode.enum';
import { Project } from 'src/app/core/models/service-mgnt-api/project.model';
import { ClrDatagridStateInterface } from '@clr/angular';
import { ProjectMetaData } from 'src/app/core/models/service-mgnt-api/project-meta-data.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.css']
})
export class ProjectsListComponent implements OnInit {

  filterDebouncer = new Subject<any>();
  lastState: ClrDatagridStateInterface;
  isNavigating: boolean = false;
  loading: boolean = true;
  totalRecords: number;
  isDeleteConfirmModalShown: boolean;
  isDeleted: boolean = false;
  isDeleteError: boolean = false;
  deleteErrorMessage: string = 'There is an error when deleting this record';
  recordApi = this.serviceMgntApi.project;
  records: ProjectMetaData[];
  currentRecord: ProjectMetaData;

  constructor(private serviceMgntApi: ServiceMgntApiService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.handleFilter();
  }

  onAdd() {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.ADD },
      relativeTo: this.route
    })
  }

  onView(record: Project) {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.VIEW, id: record.projectId },
      relativeTo: this.route
    })
  }

  onEdit(record: Project) {
    this.setNavigatingStatus();
    this.router.navigate(['edit'], {
      queryParams: { mode: EditMode.EDIT, id: record.projectId },
      relativeTo: this.route
    })
  }

  onOpenDeleteModal(record: Project) {
    this.currentRecord = record;
    this.openDeleteConfirmModal();
    this.resetMessage();
  }

  onDelete(state: ClrDatagridStateInterface) {

    this.recordApi.delete(this.currentRecord.projectId)
      .subscribe(res => {
        this.showSuccessfulDeleteMessage();
        this.refresh(this.lastState)
      }, err => {
        this.showErrorDeleteMessage(err.error.message);
      });
  }

  refresh(state: ClrDatagridStateInterface) {

    if (this.isNavigating) {
      return;
    }

    this.lastState = state;

    this.loading = true;

    // Debounce the filter event
    if (state.filters != undefined) {
      this.filterDebouncer.next(state);
      return;
    }

    this.loadData(state);

  }

  private loadData(state: ClrDatagridStateInterface) {
    this.recordApi.getMetaData(state)
      .subscribe(res => {
        this.loading = false;
        this.records = res.data;
        this.totalRecords = res.count;
      });
  }

  private handleFilter() {
    this.filterDebouncer.asObservable().pipe(
      debounceTime(environment.appConfig.filterDebounceTime)
    ).subscribe(state => {
      this.loadData(state);
    });
  }

  private openDeleteConfirmModal() {
    this.isDeleteConfirmModalShown = true;
  }

  private closeDeleteConfirmModal() {
    this.isDeleteConfirmModalShown = false;
  }

  private resetMessage() {
    this.isDeleted = false;
    this.isDeleteError = false;
  }

  private showSuccessfulDeleteMessage() {
    this.isDeleted = true;
    this.isDeleteError = !this.isDeleted;
  }

  private showErrorDeleteMessage(errMsg: string) {
    this.isDeleted = false;
    this.isDeleteError = !this.isDeleted;
    this.deleteErrorMessage = errMsg;
  }

  private setNavigatingStatus() {
    this.isNavigating = true;
  }

}
