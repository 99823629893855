import { Component, OnInit } from '@angular/core';
import {ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis} from 'ng-apexcharts';
import {Subscription, timer} from 'rxjs';
import {BrokerStatsService} from '../../../../core/services/broker-stats.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-cpu-chart',
  templateUrl: './cpu-chart.component.html',
  styleUrls: ['./cpu-chart.component.css'],
})
export class CpuChartComponent implements OnInit {
  public series: ApexAxisChartSeries;
  public chart: ApexChart;
  public dataLabels: ApexDataLabels;
  public xaxis: ApexXAxis;
  public stroke: ApexStroke;
  public grid: ApexGrid;
  public yaxis: ApexYAxis;

  reportLoader: Subscription;
  loading = true;
  cpuUsage: any[] = [];

  constructor(private service: BrokerStatsService) {

  }

  async ngOnInit() {
    this.loadReport();
    await this.initChartData();
  }

  loadReport() {
    this.reportLoader = timer(0, 5000).subscribe(val => {
      this.loading = true;
      this.service.getLoadReportInSchedule()
        .pipe(
          finalize(() => this.loading = false)
        )
        .subscribe(
          data => {
            if (data) {
              this.cpuUsage.push(data['cpu']['usage'] * 100 / data['cpu']['limit']);

              if (this.cpuUsage.length > 10) {
                this.cpuUsage.shift();
              }
              this.series = [
                {
                  name: '%CPU Usage',
                  data: this.cpuUsage,
                }
              ];
              // console.log('cpu response', this.cpuUsage);
            }
          },
          error => {
            console.log(error);
            this.loading = false;
          }
        );
    });
  }

  async initChartData() {
    this.series = [];
    this.stroke = {
      curve: 'straight',
    };
    this.grid = {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    };
    this.chart = {
      height: 140,
      type: 'line',
      zoom: {
        enabled: false,
      }
    };
    this.dataLabels = {
      enabled: false,
    };
    this.xaxis = {
      categories: ['50s', '45s', '40s', '35s', '30s', '25s', '20s', '15s', '10s', '5s'],
    };
    this.yaxis = {
      labels: {
        formatter(val) {
          return `${(val).toFixed(0)} %`;
        }
      },
      min: 0,
      max: 99,
      forceNiceScale: true,
      tickAmount: 5,
    };
  }
}
